export const config = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_n7IprOZK3",
    APP_CLIENT_ID: "3bi7n6ab7flogbj317j1h6d6k8",
    IDENTITY_POOL_ID: "us-east-1:dafe3cde-6f2d-4fbd-9813-3b36e4a5d10f",
  },
  FoveonicsAPI: "https://devapi.documentsync.com",
  ImageViewer: "https://devviewer.documentsync.com",
  Geolocation: "https://api.ipify.org/?format=json",
};

export default config;
