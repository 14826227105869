import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chat.css";

export const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isGenieTyping, setIsGenieTyping] = useState(false);
  const messagesEndRef = useRef(null);

  const USER_ID = "Document Sync Trainee";
  const GENIE_ID = "Document Sync Genie";

  useEffect(() => {
    // Add initial welcome message
    setMessages([
      {
        sender: GENIE_ID,
        content: "Hello! I'm Document Sync Genie. How can I help you today?",
        timestamp: new Date().toISOString(),
      },
    ]);
  }, []);

  useEffect(() => {
    // Scroll to bottom whenever messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isGenieTyping]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    // Add user message
    const userMessage = {
      sender: USER_ID,
      content: newMessage,
      timestamp: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setNewMessage("");
    setIsGenieTyping(true); // Show typing indicator

    try {
      // Make API call for Genie's response
      const response = await axios.post("http://localhost:5009/ProcessQuery", {
        message: newMessage,
      });

      // Add Genie's response after a small delay
      setTimeout(() => {
        const genieMessage = {
          sender: GENIE_ID,
          content: response.data.response,
          timestamp: new Date().toISOString(),
        };
        setMessages((prev) => [...prev, genieMessage]);
        setIsGenieTyping(false); // Hide typing indicator
      }, 500);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        sender: GENIE_ID,
        content:
          "I apologize, but I'm having trouble responding right now. Please try again.",
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
      setIsGenieTyping(false); // Hide typing indicator
    }
  };

  const formatMessage = (content) => {
    // Split the message by new lines
    const lines = content.split("\n");
    return lines.map((line, index) => {
      // Check if line starts with bullet points or numbers
      if (line.trim().match(/^[•*-]|^\d+\./)) {
        return (
          <li key={index}>{line.trim().replace(/^[•*-]|\d+\.\s*/, "")}</li>
        );
      }
      return <p key={index}>{line}</p>;
    });
  };

  return (
    <div className="chat-popup">
      <div className="chat-header">
        <h3>Chat with Document Sync Genie</h3>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.sender === USER_ID ? "user-message" : "genie-message"
            }`}
          >
            <div className="message-sender">{message.sender}</div>
            <div className="message-content">
              {formatMessage(message.content)}
            </div>
            <div className="message-timestamp">
              {new Date(message.timestamp).toLocaleTimeString()}
            </div>
          </div>
        ))}
        {isGenieTyping && (
          <div className="typing-indicator">
            <div className="typing-bubble"></div>
            <div className="typing-bubble"></div>
            <div className="typing-bubble"></div>
            <span className="typing-text">Genie typing... </span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={sendMessage} className="chat-input-form">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
          disabled={isGenieTyping}
        />
        <button
          type="submit"
          className="chat-send-button"
          disabled={isGenieTyping}
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default Chat;
