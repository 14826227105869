import React, { Component, Fragment } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./login.css";
import { Auth } from "aws-amplify";
import LoaderButton from "../LoaderButton";
import { Link } from "react-router-dom";
import DocumentSync from "../../Component/Images/DocumentSync-Logo.png";
import { checkIpAddress, saveAuditTrail } from "../../libs/API";
import GeolocationIp from "../../../node_modules/ip-geolocation-api-javascript-sdk/IPGeolocationAPI";
import { HelpBlock } from "react-bootstrap";
import Chat from "../Chat/chat";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
      ip: "",
      showMfa: false,
      showNonMfa: true,
      user: "",
    };
    //console.log("login props", props.props);
  }

  async componentDidMount() {
    var ipGeo = new GeolocationIp("fbb2e1a5614b4c9ca16c8e3e46b1e785", false);
    ipGeo.getGeolocation(this.handleResponse);
  }

  login = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.setState({ user: user });
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        this.setState({ isLoading: false, showMfa: true, showNonMfa: false });
      } else {
        await this.authenticate(this.state.user);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        alert(err.message);
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        alert(err.message);
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        alert(err.message);
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        alert(err.message);
      } else {
        alert(err.message);
      }
    }
  };

  openChatPopup = (e) => {
    e.preventDefault();
    const width = 400;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
      "/chat", // Your chat route
      "DocumentSyncChat",
      `width=${width},
     height=${height},
     top=${top},
     left=${left},
     toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes`
    );
  };

  authenticate = async (user) => {
    // if (
    //   !Object.keys(user.signInUserSession.idToken.payload).includes(
    //     "cognito:groups"
    //   )
    // ) {
    //   throw new Error("DocumentSync role required");
    // }
    // const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    // if (!groups.includes("DocumentSync")) {
    //   throw new Error("DocumentSync role required");
    // }
    await this.props.props.setUserPermissions(this.state.ip);
    // get ip
    //const res = await getGeoLoaction();
    //console.log(this.state.ip, "ip");
    let auditDetails = {
      emailAddress: this.state.username,
      ipAddress: this.state.ip,
      eventLog: "LogIn",
    };

    if (this.props.props.groups.includes("DocumentSync_RestrictAccessByIP")) {
      let ipParams = {
        userEmailAddress: this.state.username,
        ipAccess: this.state.ip,
      };
      // validate it has permissions or not
      // Checking the ip address from dynamo db fro Iprestriction.
      checkIpAddress(ipParams).then(async (data) => {
        if (data.status !== 200) {
          alert("Ip is restricted.");
          await Auth.signOut();
          this.setState({ isLoading: false });
        } else {
          //saving audit trail details into dynomo db
          saveAuditTrail(auditDetails).then((data) => {});
          this.props.history.push("/browse");
        }
      });
    } else {
      //saving audit trail details into dynomo db
      saveAuditTrail(auditDetails).then((data) => {
        //console.log(data);
      });
      this.props.history.push("/browse");
    }
  };

  handleResponse = (json) => {
    this.setState({ ip: json.ip });
  };

  mfaConfirm = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const code = this.state.mfaToken;
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
        this.state.user, // Return object from Auth.signIn()
        code, // Confirmation code
        "SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
      await this.authenticate(loggedUser);
    } catch (err) {
      alert(err.message);
      this.setState({ isLoading: false });
    }
  };

  renderMFaCode = () => {
    return (
      <form onSubmit={this.mfaConfirm} autoComplete="off">
        <div id="logincontent">
          <div id="logo">
            <Link to="/">
              <img src={DocumentSync} alt="DocumentSync" />
            </Link>
          </div>
          <br />
          <br />
          <HelpBlock>Enter an MFA code to complete sign-in.</HelpBlock>
          <FormGroup controlId="mfalogin">
            <ControlLabel>MFA Code:</ControlLabel>
            <FormControl
              componentClass="input"
              onChange={(e) => this.setState({ mfaToken: e.target.value })}
              type="text"
              autoComplete="off"
              required
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <LoaderButton
              block
              bsClass="btn btn-outline-secondary"
              bsSize="large"
              disabled={this.state.isLoading}
              type="submit"
              isLoading={this.state.isLoading}
              text="Submit"
              loadingText="Logging in..."
            />
          </FormGroup>
        </div>
      </form>
    );
  };

  renderLoginWithOutMfa = () => {
    return (
      <form onSubmit={this.login} autoComplete="off">
        <div id="logincontent">
          <div id="logo">
            <Link to="/">
              <img src={DocumentSync} alt="DocumentSync" />
            </Link>
          </div>
          <br />
          <br />
          <FormGroup controlId="username">
            <ControlLabel>Username:</ControlLabel>
            <FormControl
              componentClass="input"
              onChange={(e) => this.setState({ username: e.target.value })}
              type="text"
              autoComplete="off"
            ></FormControl>
          </FormGroup>
          <FormGroup controlId="password">
            <ControlLabel>Password:</ControlLabel>
            <FormControl
              componentClass="input"
              onChange={(e) => this.setState({ password: e.target.value })}
              type="password"
              autoComplete="off"
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <Link to="/login/reset">Forgot password?</Link>
            <span style={{ float: "right" }}>
              <a href="#" onClick={this.openChatPopup} className="chat-link">
                Chat with Document Sync Genie
              </a>
            </span>
          </FormGroup>

          <FormGroup>
            <LoaderButton
              block
              bsClass="btn btn-outline-secondary"
              bsSize="large"
              disabled={this.state.isLoading}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText="Logging in..."
            />
          </FormGroup>
        </div>
      </form>
    );
  };

  render() {
    return (
      <Fragment>
        <div id="loginmain">
          <div id="loginheader"></div>
          {this.state.showNonMfa && this.renderLoginWithOutMfa()}
          {this.state.showMfa && this.renderMFaCode()}
          <div id="footer">
            <p>
              &copy; {new Date().getFullYear()} Foveonics Document Solutions
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
